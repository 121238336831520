import main from "./main"


export default {
	...main,

	baseURL: process.env.VUE_APP_BACKEND_HOST,
	apartmentType: process.env.VUE_APP_APARTMENT_TYPE,
	analytics: null,
	debug: true
}
